<template>
<footer class="">
    <hr class="mx-auto max-w-screen-xl border-1 border-t2 mb-8" />
    <div class="px-2 mx-auto max-w-screen-xl">
        <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
                <div class="flex h-full">
                  <a href="/" class="block">
                    <img src="/img/logo-img.png" class="h-10 md:h-12" alt="Logo" />
                  </a>
                </div>
            </div>
   <div>
      <ul class="text-t1">
        <li class="py-1">
          <a href="/schools" class="text-t2" aria-current="page">For Schools</a>
        </li>
        <li class="py-1">
          <a href="/highered" class="text-t2" aria-current="page">For Higher Education</a>
        </li>
        <li class="py-1">
          <a href="/about" class="text-t2" aria-current="page">About</a>
        </li>
        <li class="py-1">
          <a href="/contact" class="text-t2">Book a demo </a>
        </li>
        <li class="py-1 pb-8">
          <a href="/contact" class="text-t2">Contact </a>
        </li>
      </ul>
    </div>
            <div>
                <ul class="text-t1 py-1">
                    <li> <a href="https://twitter.com/scaleuprobotics" class="hover:underline text-t1">X / Twitter</a> </li>
                </ul>
                <ul class="text-t2 py-1">
                    <li> <a href="https://www.linkedin.com/company/scaleup-robotics/" class="hover:underline text-t1">LinkedIn</a> </li>
                </ul>
                <ul class="text-t2 py-1">
                    <li> <a href="/privacy" class=" text-t1 hover:underline">Privacy & Terms</a> </li>
                </ul>
            </div>
        </div>

        <div class="py-8 flex justify-center text-t2 text-sm">
            © 2024 Scaleup Robotics AB. All Rights Reserved. Made with ♥ in Stockholm, Sweden.
        </div>
    </div>
</footer>
</template>
